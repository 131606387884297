import { createApp } from 'vue';
import App from './App.vue';
import '@/assets/css/w3.css'
import '@/assets/css/main.css'
import router from './router/index';
import { createHead } from 'unhead';

// eslint-disable-next-line no-unused-vars
const head = createHead();

const app = createApp(App)
  .use(router);

await router.isReady();
app.mount('#app');
