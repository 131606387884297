<template>
  <!-- FrontPage -->
  <header class="w3-container w3-black w3-center">
    <div id="logoContainer">
      <svg viewBox="0 0 490 250" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">

        <!-- First Line -->
        <text x="0" y="90" font-family="'League Spartan', sans-serif" font-weight="700" font-size="124" letter-spacing="-7" fill="white">Coach</text>

        <!-- Embedding the runner silhouette SVG -->
        <image xlink:href="../assets/juoksija.svg" x="328" y="0" width="140" height="100"/>

        <!-- Second Line -->
        <!-- Embedding the hockey player silhouette SVG -->
        <image xlink:href="../assets/kiekkoilija.svg" x="86" y="100" width="106" height="106"/>

        <text x="212" y="180" font-family="'League Spartan', sans-serif" font-weight="700" font-size="124" letter-spacing="-7" fill="white">Andy</text>
        <text x="0" y="240" font-family="'League Spartan', sans-serif" font-weight="200" font-size="32" letter-spacing="-2" fill="white">Koulutettu hieroja ja liikunnan ammattilainen</text>
      </svg>
    </div>

  </header>

  <div class="w3-row w3-center w3-light-grey">
    <h1 style="margin-top: 48px;">Hieronta- ja valmennus&shy;palvelut</h1>
  </div>

  <!-- Hieronta -->
  <div class="w3-padding-64 w3-container w3-light-grey section">
    <div class="w3-content">
      <div>
        <h2 id="hieronta" class="sectionHead">Hieronta</h2>
        <h3 class="w3-padding-32">Ammattimaista hierontaa kaikenlaisille asiakkaille.</h3>

        <p class="w3-text-black">Urheilijataustani ja ihmisen anatomian ja fysiologian syvällinen tuntemus ovat auttaneet minua kehittymään hierojan ammatissani.
          Tämän tietämykseni avulla räätälöin hoidon kunkin asiakkaan yksilöllisten tarpeiden mukaan, 
          olipa kyseessä sitten lihasjännityksen lievittäminen, rentoutumisen edistäminen tai palautumisen edistäminen.</p>
        <router-link to="/hieronta" class="w3-button w3-round w3-black w3-large">Lue lisää</router-link>
      </div>
    </div>
  </div>

  <!-- Valmennus -->
  <div class="w3-padding-64 w3-container section">
    <div class="w3-content">
      <div>
        <h2 id="valmennus" class="sectionHead">Valmennus</h2>
        <h3 class="w3-padding-32">Huippuluokan valmennuspalvelut kaikentasoisille kuntoilijoille.</h3>

        <p class="w3-text-grey">Tarjoan huippuluokan valmennuspalveluita, jotka on suunniteltu nostamaan urheilijat heidän huippusuoritukseensa. 
          Minulla on runsaasti asiantuntemusta eri urheilulajeista, 
          mikä takaa yksilölliset ja räätälöidyt harjoitusohjelmat kaiken tasoisille urheilijoille ja kuntoilijoille.</p>
        <router-link to="/valmennus" class="w3-button w3-round w3-black w3-large">Lue lisää</router-link>
      </div>
    </div>
  </div>

  <!-- Tietoa minusta -->
  <div class="w3-row-padding w3-padding-64 w3-container w3-light-grey section">
    <div class="w3-content">
      <div>
        <h2 id="tietoaminusta" class="sectionHead">Tietoa minusta</h2>
        <h3 class="w3-padding-32">Olen koulutettu hieroja ja liikunnanohjaaja (AMK). Liikunnanohjaajana olen erikoistunut urheiluvalmennukseen.</h3>

        <p class="w3-text-black">Minulla on urheilijatausta jääkiekkoilijana ja yleisurheilijana. Lisäksi olen toiminut usean vuoden ajan jääkiekon vastuuvalmentajana eri-ikäisten parissa ja yleisurheilun puolella pikajuoksuvalmentajana ja Kainuun alueen yleisurheiluohjaajakouluttajana.</p>
        <p class="w3-text-black">Urheilijatausta ja liikunnanohjaajan koulutus ovat vaikuttaneet merkittävästi kehittymiseeni hierojan ammatissa. Minulle on karttunut kokonaisvaltainen tietämys ihmisen anatomiasta, fysiologiasta, terveydestä ja hyvinvoinnista.</p>
        <p class="w3-text-black">Valmennusasiakkaina minulla on Mestis- ja U20-tason jääkiekkoilijoita. Valmennuksessa kehitetään urheilijan fyysisiä ominaisuuksia ja henkilökohtaisia laji- ja pelitaitoja. Valmennettavien pelaajieni kehitys on ollut huimaa.</p>
        <p class="w3-text-black">Minulla on käynyt hieronta-asiakkaina eläkeläisiä, työssäkäyviä ja eri-ikäisiä urheilijoita. Olen saanut hyvää palautetta hierojan ammattitaidostani kaikilta asiakkailtani, erityisesti Edustus Hokin ja Hokin U20 joukkueen pelaajilta.</p>
        <router-link to="/tietoaminusta" class="w3-button w3-round w3-black w3-large">Lue lisää</router-link>
      </div>
    </div>
  </div>

  <!-- Ota yhteyttä -->
  <div class="w3-row-padding w3-padding-64 w3-container section">
    <div class="w3-content">
      <div>
        <h2 id="yhteys" class="sectionHead">Ota yhteyttä</h2>
        <h3 class="w3-padding-32">Tilaa valmennuspalveluita tai kysy lisätietoja.</h3>
        <p class="w3-text-grey">
          <b>Voit tilata valmennuspalveluita</b> ottamalla yhteyttä suoraan minuun puhelimitse tai sähköpostilla. Loydät yhteystietoni alta. Katso tarkemmat tiedot valmennuksista täältä:
          <br/>
          <router-link to="/valmennus" class="w3-button w3-round w3-black w3-large">Valmennus</router-link>
        </p>
        <p class="w3-text-grey">
          <b>Hieronnan ajanvaraus</b> tapahtuu Vello -ajanvarauspalvelun kautta täältä (avautuu uuteen välilehteen):
          <br/>
          <a class="w3-button w3-round w3-black w3-large" href="https://vello.fi/coachandy-hieronta/" target="_blank">Hieronnan ajanvaraus</a>
        </p>
        <p class="w3-text-grey">Voit tiedustella lisää palveluistani ottamalla yhteyttä puhelimitse tai sähköpostilla. Loydät yhteystietoni alta.</p>
        <p class="w3-text-grey"><b>Puhelin:</b> <a href="tel:+358503761776">050 376 1776</a></p>
        <p class="w3-text-grey"><b>Sähköposti:</b> <a href="mailto:antti@coachandy.fi">antti@coachandy.fi</a></p>
      </div>

      <router-link to="/yhteys" class="w3-button w3-round w3-black w3-large">Lue lisää</router-link>

    </div>
  </div>
  
</template>

<script>
import { useSeoMeta, useHead } from 'unhead';

export default {
    name: "FrontPage",
    setup() {
      useSeoMeta({
        title: 'Hieronta ja Valmennuspalvelut Kajaanissa | Coach Andy',
        ogTitle: 'Hieronta ja Valmennuspalvelut Kajaanissa | Coach Andy',
        description: 'Koulutettu hieroja ja liikunnanohjaaja Antti Ovaska (Coach Andy) tarjoaa ammattimaisia hieronta- ja valmennuspalveluja Kajaanissa.',
        ogDescription: 'Koulutettu hieroja ja liikunnanohjaaja Antti Ovaska (Coach Andy) tarjoaa ammattimaisia hieronta- ja valmennuspalveluja Kajaanissa.',
        ogUrl: 'https://coachandy.fi'
      });
      useHead({
        link: [{
          rel: 'canonical', href: 'https://coachandy.fi'
        }],
        meta: [
          {
            name: "keywords",
            content: "hieronta, hieroja, valmennus, valmentaja, urheiluvalmennus, trainer, personal trainer, koulutettu hieroja, hieroja kajaani, hieronta kajaani, valmennus kajaani, coach andy"
          }
        ]
      });
    },
    mounted() {
      document.dispatchEvent(new Event('render-complete')); 
    }
}
</script>
