<template>
  <NavBar />
  <router-view></router-view>
  <BottomInfo />
  <FooterSection />
</template>

<script>
import NavBar from './components/NavBar.vue'
import BottomInfo from './components/BottomInfo.vue'
import FooterSection from './components/FooterSection.vue'

export default {
  name: 'App',
  components: {
    NavBar, BottomInfo, FooterSection
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
