<template>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Coach Andy",
          "item": "https://coachandy.fi"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Valmennus"
        }]
      }
    </component>
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Valmennus",
        "category": {
          "@type": "PhysicalActivityCategory",
          "@category": "StrengthTraining"
        },
        "url": "https://coachandy.fi/valmennus",
        "logo": "https://coachandy.fi/runner-black.png",
        "image": "https://coachandy.fi/runner-black.png",
        "description": "Ammattimaiset urheiluvalmennuspalvelut verkossa ja Kajaanissa",
        "hoursAvailable": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Monday",
          "opens": "10:00",
          "closes": "18:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Tuesday",
          "opens": "10:00",
          "closes": "18:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Wednesday",
          "opens": "10:00",
          "closes": "18:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Thursday",
          "opens": "10:00",
          "closes": "18:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Friday",
          "opens": "10:00",
          "closes": "18:00"}
        ]
      }
    </component>
  </teleport>
  <!-- CoachingPage -->
  <header class="bgimg w3-container w3-blue-gray w3-center">
    <p class="w3-margin pageTitle">Valmen&shy;nus</p>
    <p>Huippuluokan valmennusta</p>
  </header>

  <!-- Valmennus -->
  <div class="w3-row-padding w3-padding-64 w3-container">
    <div class="w3-content">

      <div>
        <h1 id="valmennus" class="sectionHead">Tarjoamani valmennus&shy;palvelut</h1>
        <p class="w3-text-grey">Tarjoan huippuluokan valmennuspalveluita, jotka on suunniteltu nostamaan urheilijat heidän huippusuoritukseensa. 
          Minulla on runsaasti asiantuntemusta eri urheilulajeista, 
          mikä takaa yksilölliset ja räätälöidyt harjoitusohjelmat kaiken tasoisille urheilijoille ja kuntoilijoille.</p>
      </div>

      <div>
        <h2>Valmennuksen ostaminen</h2>
        <p class="w3-text-grey">Kun löydät palveluhinnastosta itsellesi sopivan valmennuspalvelun, ole yhteydessä minuun ja kerro hieman valmennustarpeestasi. Voit lähettää sähköpostia, tekstiviestiä tai vaikka soittaa.
          <!-- <ul class="contentList">
            <li class="w3-text-red">Valmennuskortin sopimusehdot (linkki tulossa)</li>
            <li class="w3-text-red">Valmennuspaketin sopimusehdot (linkki tulossa)</li>
          </ul> -->
        </p>
        <!-- <p class="w3-text-grey">Kun olet tutustunut sopimusehtoihin, ole yhteydessä minuun ja kerro hieman valmennustarpeestasi. Voit lähettää sähköpostia, tekstiviestiä tai vaikka soittaa.</p> -->
        <p class="w3-text-grey">Tämän jälkeen teemme valmennussopimuksen ja voimme aloittaa treenit.</p>
        <p></p>
      </div>

    </div>
  </div>

  <div class="w3-row-padding w3-container w3-light-gray">
    <div class="w3-content">
      <h2 class="">Jää&shy;kiekkoilijalle</h2>
      <p class="w3-text-black">Jääkiekko on vaativa urheilulaji, jossa tarvitaan todella paljon erilaisia taitoja, minkä lisäksi pelaajan tulee olla fyysisesti erittäin hyvässä kunnossa.
        Tarvitaan nopeutta, voimaa ja kestävyyttä.</p>
      <p class="w3-text-black">Jääkiekkoilijalle tarjoan henkilökohtaista <b>luistelu- ja taitovalmennusta sekä fysiikkavalmennusta</b> nopeuden, voiman ja kestävyyden kehittämiseksi.</p>
      
      <h2>Jalka&shy;palloilijalle</h2>
      <p class="w3-text-black">Jalkapallo vaatii pelaajalta todella hyvää kestävyyttä, mutta tarvitaan myös nopeutta, nopeuskestävyyttä ja nopeustaitavuutta.</p>
      <p class="w3-text-black">Ensisijaisesti tarjoan jalkapalloilijoille ilman palloa tehtävää <b>nopeusharjoittelua</b>.
        Pystyn tekemään pelaajasta <b>paremman ja nopean juoksijan hyvän juoksutekniikan kautta</b>. Ilman hyvää tekniikkaa juoksu ei ole taloudellista.</p>
      
      <h2>Pesä&shy;palloilijalle</h2>
      <p class="w3-text-black">Pesäpalloilijan täytyy olla nopea juoksemaan pesältä toiselle, eli tarvitaan nopeutta lyhyellä matkalla.
        Tämä vaatii myös erilaista harjoittelua pidemmällä matkalla ja nopeusominaisuuden kehittämistä.</p>
      
      <h2>Yleis&shy;urheilijalle</h2>
      <p class="w3-text-black">Yleisurheilijoille tarjoan <b>juoksu- ja nopeusvalmennusta erityisesti pikamatkoilla</b>.
        Juoksukin vaatii taitoa. Haaste on löytää rentous, mutta samalla juosta kovaa.</p>
      
      <h2>Kuntoilijalle</h2>
      <p class="w3-text-black">Tarjoan yksilöllistä valmennusta kaikenlaisille kuntoilijoille. Haluatko parempaan kuntoon kuntosalilla tai pudottaa painoa?
        Tai paremmaksi uimariksi? Tähtäätkö triathlonille tai kenties puolimaratonille?</p>
      <p class="w3-text-black"><b>Opastan oikeat tekniikat, laadin henkilökohtaiset harjoitusohjelmat ja annan ruokavalioneuvontaa</b>, joilla pääset tavoitteisiisi.</p>
    </div>
  </div>

  <!-- Palveluhinnasto -->
  <div class="w3-row-padding w3-padding-64 w3-container">
    <div class="w3-content">
      <h2 class="">Palvelu&shy;hinnasto</h2>

      <!-- Valmennuskortit -->
      <div class="w3-panel w3-light-gray">

        <h3>Kerta&shy;valmennus 60 min
          <!-- <i class="fa-solid fa-layer-group w3-text-black w3-hide-small" style="margin-left: 24px;"></i> -->
        </h3>

        <ul class="w3-ul">
          <li class="w3-bar">
            <div class="w3-bar-item">
              <p class="priceTag w3-button w3-round w3-green">35 € <span class="w3-medium">(sis. ALV)</span></p>
              <p class="w3-text-black">Kertavalmennus tarkoittaa henkilökohtaisesti ohjattua harjoitusta, 
                joka ei välttämättä kuulu mihinkään harjoitusohjelmaan. 
                Esimerkiksi taitoharjoituksen pitäminen jääkiekkoilijalle, 
                juoksutekniikkaohjaus yleisurheilijalle tai vaikkapa kuntosaliohjauksen pitäminen yksittäiselle kuntoilijalle.
              </p>
            </div>
          </li>

          <!-- <li class="w3-bar">
            <div class="w3-bar-item">
              <h4>
                5 x 60 min valmennus&shy;kortti
              </h4>
              <p class="priceTag w3-button w3-round w3-green">175 € <span class="w3-medium">(sis. ALV 24%)</span></p>
              <p class="w3-text-black">Viisi henkilökohtaisesti ohjattua harjoitusta yhdelle.</p>
            </div>
          </li>

          <li class="w3-bar">
            <div class="w3-bar-item">
              <h4>
                10 x 60 min valmennus&shy;kortti
              </h4>
              <p class="priceTag w3-button w3-round w3-green">320 € <span class="w3-medium">(sis. ALV 24%)</span></p>
              <p class="w3-text-black">Kymmenen henkilökohtaisesti ohjattua harjoitusta yhdelle.</p>
            </div>
          </li>

          <li class="w3-bar">
            <div class="w3-bar-item">
              <h4>
                1 x 60 min pienryhmä&shy;valmennus&shy;kortti
              </h4>
              <p class="priceTag w3-button w3-round w3-green">78 € <span class="w3-medium">(sis. ALV 24%)</span></p>
              <p class="w3-text-black">60 minuutin ohjattu harjoitus pienryhmille. <b>Ryhmän koko 3-6 henkilöä.</b></p>
            </div>
          </li>

          <li class="w3-bar">
            <div class="w3-bar-item">
              <h4>
                1 x 60 min ryhmä&shy;valmennus&shy;kortti
              </h4>
              <p class="priceTag w3-button w3-round w3-green">99 € <span class="w3-medium">(sis. ALV 24%)</span></p>
              <p class="w3-text-black">60 minuutin ohjattu harjoitus ryhmille. <b>Ryhmän koko 7-15 henkilöä.</b></p>
              <p class="w3-text-black">Mitä suurempi ryhmäkoko, sitä vähemmän henkilökohtaista valmennusta ja palautetta voin antaa.</p>
            </div>
          </li>

          <li class="w3-bar">
            <div class="w3-bar-item">
              <h4>
                5 x 60 min ryhmä&shy;valmennus&shy;kortti
              </h4>
              <p class="priceTag w3-button w3-round w3-green">445 € <span class="w3-medium">(sis. ALV 24%)</span></p>
              <p class="w3-text-black">60 minuutin ohjattu harjoitus ryhmille. <b>Ryhmän koko 7-15 henkilöä.</b></p>
              <p class="w3-text-black">Mitä suurempi ryhmäkoko, sitä vähemmän henkilökohtaista valmennusta ja palautetta voin antaa.</p>
            </div>
          </li> -->

        </ul>

      </div>

      <!-- Valmennuspaketit -->
      <div class="w3-panel w3-light-gray">

        <h3>Henkilö&shy;kohtainen valmennus&shy;paketti 3kk</h3>
        <p class="priceTag w3-button w3-round w3-green">350 € <span class="w3-medium">(sis. ALV)</span></p>
        <!-- <p class="w3-text-black">Valmennuspakettiin kuuluu harjoitusohjelman lisäksi henkilökohtaista ohjausta ja kehityksen seurantaa.
          Ohjausten määrä ja seurannan taso määräytyy valmennuspaketin tason mukaisesti.</p>
        <p class="w3-text-black">Harjoitusohjelman laatiminen alkaa aina alkuhaastattelulla ja henkilökohtaisten tavoitteiden asettamisella.</p>
        <p class="w3-text-black">Kontaktiohjaukset järjestetään Kajaanissa.</p>
        <p class="w3-text-black">Valmennuspakettia tilattaessa tehdään valmennussopimus <span style="color: red;">(linkki)</span>, jossa kuvataan tarkemmat sopimusehdot.</p>
        <p class="w3-text-black">Valmennuskorteilla voit täydentää valmennuspakettia ja hankkia lisäohjauksia.</p> -->
        
        <ul class="w3-ul">
          <li id="iTasonValmennus" class="w3-bar" style="padding-top: 24px;">
            <div class="w3-bar-item">
              
              <p class="w3-text-black">Ammattilaisen laatima harjoitusohjelma itsenäiselle urheilijalle.
                Sopii myös verkkovalmennukseksi eri paikkakunnalla asuvalle urheilijalle, 
                joka haluaa oman lajinsa ja tavoitteidensa mukaisen harjoitusohjelman.</p>
              <ul class="contentList">
                <li>Alkuhaastattelu ja tavoitteiden asettaminen</li>
                <li>Harjoitusohjelma</li>
                <li>Sisältää kaksi kontaktiohjausta</li>
              </ul>
            </div>
          </li>

          <!-- <li id="iiTasonValmennus" class="w3-bar" style="padding-top: 24px;">
            <div class="w3-bar-item">
              <h4>
                II-tason valmennus&shy;paketti
              </h4>
              <p class="priceTag w3-button w3-round w3-green">248 € <span class="w3-medium">(sis. ALV 24%)</span></p>
              <p class="w3-text-black">Edistyneempi kokonaisuus, joka sisältää myös harjoitusohjelman muutoksen ja väliseurannan. Myös kontaktiohjauksen määrää on lisätty.</p>
              <ul class="contentList">
                <li>Alkuhaastattelu ja tavoitteiden asettaminen</li>
                <li>Harjoitusohjelma</li>
                <li>Jatko-ohjelma</li>
                <li>Edistymisen seuranta</li>
                <li>Sisältää 3 kontaktiohjausta (käytettävä 3 kk kuluessa)</li>
                <li>Kesto 3 kk tavoitteista riippuen</li>
              </ul>
            </div>
          </li>

          <li id="iiiTasonValmennus" class="w3-bar" style="padding-top: 24px;">
            <div class="w3-bar-item">
              <h4>
                III-tason valmennus&shy;paketti
              </h4>
              <p class="priceTag w3-button w3-round w3-green">589 € <span class="w3-medium">(sis. ALV 24%)</span></p>
              <p class="w3-text-black">Pitkän tähtäimen ohjelma.
                Esimerkiksi jääkiekkoilijan kesäharjoituskausi jakautuu kolmeen osaan, jotka kaikki vaativat oman harjoitusohjelmansa.
                Sama pätee myös muihin lajeihin. Pitkän tähtäimen tavoitteiden saavuttaminen vaatii, että ohjelmaa täytyy välillä muuttaa.</p>
              <p class="w3-text-black">Pelkkää harjoitusohjelmaa tavoitteellisempi kokonaisuus, 
                jossa laaditaan henkilökohtainen ruokaohjelma. 
                Hyvä ja laadukas ruokavalio vaikuttaa merkittävästi siihen millaiseen lopputulokseen päästään.</p>
              <ul class="contentList">
                <li>Alkuhaastattelu ja tavoitteiden asettaminen</li>
                <li>Harjoitusohjelma</li>
                <li>Jatko-ohjelmat</li>
                <li>Ruokavalioneuvonta</li>
                <li>Edistymisen seuranta</li>
                <li>Sisältää 10 kontaktiohjausta (käytettävä 4 kk kuluessa)</li>
                <li>Kesto n. 4 kk</li>
              </ul>
            </div>
          </li> -->

        </ul>
      </div>
    </div>
  </div>

</template>

<script>
import { useSeoMeta, useHead } from 'unhead';

export default {
    name: "CoachingPage",
    setup() {
      useSeoMeta({
        title: 'Ammattimaiset valmennuspalvelut verkossa ja Kajaanissa | Coach Andy',
        ogTitle: 'Ammattimaiset valmennuspalvelut verkossa ja Kajaanissa | Coach Andy',
        description: 'Liikunnanohjaaja Antti Ovaska (Coach Andy) tarjoaa ammattimaisia valmennuspalveluja verkossa ja paikan päällä Kajaanissa.',
        ogDescription: 'Liikunnanohjaaja Antti Ovaska (Coach Andy) tarjoaa ammattimaisia valmennuspalveluja verkossa ja paikan päällä Kajaanissa.',
        ogUrl: 'https://coachandy.fi/valmennus',
      });
      useHead({
        link: [{
          rel: 'canonical', href: 'https://coachandy.fi/valmennus'
        }],
        meta: [
          {
            name: "keywords",
            content: "valmennus, valmentaja, urheiluvalmennus, training, personal trainer, valmennus kajaani, coach andy"
          }
        ]
      });
    },
    mounted() {
      document.dispatchEvent(new Event('render-complete')); 
    }
}
</script>
<style scoped>
  .bgimg {
    background-image: url("../assets/plants-1.jpg");
    background-size: cover;
    background-position: center;
  }
  ul.contentList {
    font-size: x-large;
  }

  .priceTag {
    cursor: default;
    
  }
  .priceTag:hover {
    cursor: default;
    background-color: #4CAF50!important;
    color: white!important;
  }
  /* Media query for viewports less than 850px */
  @media (max-width: 849px) {
    ul.contentList {
      font-size: large;
    }
  }
</style>
