<template>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Coach Andy",
          "item": "https://coachandy.fi"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Tietoa minusta"
        }]
      }
    </component>
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "AboutPage",
        "name": "Tietoa Coach Andystä",
        "description": "Lisätietoja Coach Andystä, joka tarjoaa ammattimaisia hieronta- ja valmennuspalveluja Kajaanissa.",
        "url": "https://coachandy.fi/tietoaminusta",
        "image": "https://coachandy.fi/runner-black.png",
        "publisher": {
          "@type": "Organization",
          "name": "Coach Andy",
          "logo": "https://coachandy.fi/logo.png"
        },
        "mainEntity": {
          "@type": "Person",
          "name": "Antti Ovaska",
          "jobTitle": "Professional Coach",
          "description": "Koulutettu hieroja ja liikunnanohjaaja Antti Ovaska (Coach Andy) tarjoaa ammattimaisia hieronta- ja valmennuspalveluja Kajaanissa.",
          "telephone": "050 376 1776",
          "email": "antti@coachandy.fi"
        }
      }
    </component>
  </teleport>
  <!-- InfoPage -->
  <header class="bgimg w3-container w3-blue-gray w3-center">
    
      <p class="w3-margin pageTitle">Antti Ovaska</p>
      <p>Koulutettu hieroja ja liikunnan ammattilainen</p>
    
  </header>

  <!-- Tietoa minusta -->
  <div class="w3-row-padding w3-padding-64 w3-container">
    <div class="w3-content">
      <div>
        <h1 id="tietoaminusta" class="sectionHead">Tietoa minusta</h1>
        <h5 class="w3-padding-32">Olen koulutettu hieroja ja liikunnanohjaaja (AMK). Liikunnanohjaajana olen erikoistunut urheiluvalmennukseen.</h5>

        <p class="w3-text-grey">Minulla on urheilijatausta jääkiekkoilijana ja yleisurheilijana. Lisäksi olen toiminut usean vuoden ajan jääkiekon vastuuvalmentajana eri-ikäisten parissa ja yleisurheilun puolella pikajuoksuvalmentajana ja Kainuun alueen yleisurheiluohjaajakouluttajana.</p>
        <p class="w3-text-grey">Urheilijatausta ja liikunnanohjaajan koulutus ovat vaikuttaneet merkittävästi kehittymiseeni hierojan ammatissa. Minulle on karttunut kokonaisvaltainen tietämys ihmisen anatomiasta, fysiologiasta, terveydestä ja hyvinvoinnista.</p>
        <p class="w3-text-grey">Valmennusasiakkaina minulla on Mestis- ja U20-tason jääkiekkoilijoita. Valmennuksessa kehitetään urheilijan fyysisiä ominaisuuksia ja henkilökohtaisia laji- ja pelitaitoja. Valmennettavien pelaajieni kehitys on ollut huimaa.</p>
        <p class="w3-text-grey">Minulla on käynyt hieronta-asiakkaina eläkeläisiä, työssäkäyviä ja eri-ikäisiä urheilijoita. Olen saanut hyvää palautetta hierojan ammattitaidostani kaikilta asiakkailtani, erityisesti Edustus Hokin ja Hokin U20 joukkueen pelaajilta.</p>
      </div>

    </div>
  </div>
  
</template>

<script>
import { useSeoMeta, useHead } from 'unhead';

export default {
    name: "InfoPage",
    setup() {
      useSeoMeta({
        title: 'Koulutettu hieroja ja liikunnanohjaaja Antti Ovaska | Coach Andy',
        ogTitle: 'Koulutettu hieroja ja liikunnanohjaaja Antti Ovaska | Coach Andy',
        description: 'Koulutettu hieroja ja liikunnanohjaaja Antti Ovaska (Coach Andy) tarjoaa ammattimaisia hieronta- ja valmennuspalveluja Kajaanissa.',
        ogDescription: 'Koulutettu hieroja ja liikunnanohjaaja Antti Ovaska (Coach Andy) tarjoaa ammattimaisia hieronta- ja valmennuspalveluja Kajaanissa.',
        ogUrl: 'https://coachandy.fi/tietoaminusta'
      });
      useHead({
        link: [{
          rel: 'canonical', href: 'https://coachandy.fi/tietoaminusta'
        }],
        meta: [
          {
            name: "keywords",
            content: "hieronta, hieroja, valmennus, valmentaja, urheiluvalmennus, training, personal trainer, koulutettu hieroja, hieroja kajaani, hieronta kajaani, valmennus kajaani, coach andy"
          }
        ]
      });
    },
    mounted() {
      document.dispatchEvent(new Event('render-complete')); 
    }
}
</script>
<style scoped>
  .bgimg {
    background-image: url("../assets/pinaatti.jpg");
    background-size: cover;
    background-position: center;
  }
</style>