<template>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Coach Andy",
          "item": "https://coachandy.fi"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Hieronta"
        }]
      }
    </component>
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Hieronta",
        "category": "Hieronta",
        "url": "https://coachandy.fi/hieronta",
        "logo": "https://coachandy.fi/logo.png",
        "image": "https://coachandy.fi/runner-black.png",
        "description": "Ammattimaista hierontaa Kajaanissa",
        "hoursAvailable": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Monday",
          "opens": "10:00",
          "closes": "18:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Tuesday",
          "opens": "10:00",
          "closes": "18:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Wednesday",
          "opens": "10:00",
          "closes": "18:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Thursday",
          "opens": "10:00",
          "closes": "18:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Friday",
          "opens": "10:00",
          "closes": "18:00"}
        ]
      }
    </component>
  </teleport>
  <!-- MassagePage -->
  <header class="bgimg w3-container w3-blue-gray w3-center">
    <p class="w3-margin pageTitle">Hieronta</p>
    <p>Ammattimaista hierontaa</p>
  </header>

  <!-- Hieronta -->
  <div class="w3-row-padding w3-padding-64 w3-container">
    <div class="w3-content">
      <div>
        <h1 id="hieronta" class="sectionHead">Hieronta</h1>
        <h3 class="w3-padding-32">Ammattimaista hierontaa kaikenlaisille asiakkaille.</h3>

        <p class="w3-text-grey">Urheilijataustani ja ihmisen anatomian ja fysiologian syvällinen tuntemus ovat auttaneet minua kehittymään hierojan ammatissani.
          Tämän tietämykseni avulla räätälöin hoidon kunkin asiakkaan yksilöllisten tarpeiden mukaan, 
          olipa kyseessä sitten lihasjännityksen lievittäminen, rentoutumisen edistäminen tai palautumisen edistäminen.</p>
        
        <p>
          <a class="w3-button w3-round w3-black w3-large" href="https://vello.fi/coachandy-hieronta/" target="_blank">
            Ajanvaraus
          </a>
        </p>
      </div>

    </div>
  </div>

  <!-- Palveluhinnasto -->
  <div class="w3-row-padding w3-container">
    <div class="w3-content">
      <h2 class="">Palvelu&shy;hinnasto</h2>

      <!-- Hierontapalvelut -->
      <div class="w3-panel w3-light-gray">

        <h3>
          Hieronnat
        </h3>
        <h4>Ohjeita</h4>
        <p>Hieronta-aika on kokonaisaika, joka alkaa sovittuun aikaan ja loppuu kun varatun hieronnan kesto on kulunut.</p>
        <h4>Varausvahvistus ja peruutukset</h4>
        <p>Saat varauksesta varausvahvistuksen sähköpostiin. Tämän kautta voit itse peruuttaa varauksen, mikäli olet estynyt tulemasta hierontaan.
          Varattu hieronta-aika on peruttava viimeistään 24 tuntia ennen sovittua hieronta-aikaa, muuten hieronnan hinnasta veloitetaan 50 %.
        </p>
        <h4>Maksutavat</h4>
        <p>
          <ul class="w3-ul">
            <li>MobilePay</li>
            <li>ePassi</li>
            <li>Käteinen</li>
            <li>Lasku</li>
          </ul>
        </p>

          <ul class="w3-ul">

            <li class="w3-bar">
              <div class="w3-bar-item">
                <h4>
                  Hieronta 45 min
                </h4>
                <p class="w3-button w3-round w3-green">
                  <a style="text-decoration:none;" href="https://vello.fi/coachandy-hieronta/" target="_blank">35 €</a>
                </p>
                <p>45 minuutin osahieronta.</p>
              </div>
            </li>

            <li class="w3-bar">
              <div class="w3-bar-item">
                <h4>
                  Hieronta 60 min
                </h4>
                <p class="w3-button w3-round w3-green">
                  <a style="text-decoration:none;" href="https://vello.fi/coachandy-hieronta/" target="_blank">45 €</a>
                </p>
                <p>60 minuutin puolihieronta.</p>
              </div>
            </li>

            <li class="w3-bar">
              <div class="w3-bar-item">
                <h4>
                  Hieronta 90 min
                </h4>
                <p class="w3-button w3-round w3-green">
                  <a style="text-decoration:none;" href="https://vello.fi/coachandy-hieronta/" target="_blank">65 €</a>
                </p>
                <p>90 minuutin hieronta.</p>
              </div>
            </li>

            <li class="w3-bar">
              <div class="w3-bar-item">
                <h4>
                  Hieronta 120 min
                </h4>
                <p class="w3-button w3-round w3-green">
                  <a style="text-decoration:none;" href="https://vello.fi/coachandy-hieronta/" target="_blank">85 €</a>
                </p>
                <p>120 minuutin hieronta.</p>
              </div>
            </li>

        </ul>

      </div>
    </div>
  </div>
  <!-- Galleria -->
  <div class="">
    <div class="w3-content">
      <img src="../assets/Hierontatila_1.jpg" alt="Hierontatila" style="width: 100%;"/>
      <div class="w3-container">
        <p>Hierontatila</p>
      </div>
    </div>
  </div>
  <div class="">
    <div class="w3-content">
      <img src="../assets/Hierontatila_2.jpg" alt="Hierontatila" style="width: 100%;"/>
      <div class="w3-container">
        <p>Hierontatila</p>
      </div>
    </div>
  </div>

  <div class="w3-row-padding w3-padding-64 w3-container">
    <div class="w3-content">
    </div>
  </div>

</template>

<script>
import { useSeoMeta, useHead } from 'unhead';

export default {
    name: "MassagePage",
    setup() {
      useSeoMeta({
        title: 'Ammattimaista hierontaa Kajaanissa | Coach Andy',
        ogTitle: 'Ammattimaista hierontaa Kajaanissa | Coach Andy',
        description: 'Koulutettu hieroja Antti Ovaska (Coach Andy) tarjoaa ammattimaista hierontaa Kajaanissa kaikenlaisille asiakkaille.',
        ogDescription: 'Koulutettu hieroja Antti Ovaska (Coach Andy) tarjoaa ammattimaista hierontaa Kajaanissa kaikenlaisille asiakkaille.',
        ogUrl: 'https://coachandy.fi/hieronta',
      });
      useHead({
        link: [{
          rel: 'canonical', href: 'https://coachandy.fi/hieronta'
        }],
        meta: [
          {
            name: "keywords",
            content: "hieronta, hieroja, koulutettu hieroja, hieroja kajaani, hieronta kajaani, coach andy"
          }
        ]
      });
    },
    mounted() {
      document.dispatchEvent(new Event('render-complete')); 
    }
}
</script>
<style scoped>
  .bgimg {
    background-image: url("../assets/plants-2.jpg");
    background-size: cover;
    background-position: center;
  }
  h1.pageTitle {
    text-shadow: 0px  0px   4px #555;
  }
</style>