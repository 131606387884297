<template>
  <!-- FooterSection -->
  <div class="w3-container w3-white w3-center w3-opacity w3-padding-64">
    <div class="w3-content w3-row">
      <h2 class="w3-margin quote">Pursue Greatness</h2>
    </div>
  </div>

  <!-- Footer -->
  <footer class="w3-container w3-padding-64 w3-center w3-opacity">  
    <div class="w3-xlarge w3-padding-32">
      <!-- <i class="fa fa-facebook-official w3-hover-opacity"></i> -->
      <!-- <i class="fa fa-instagram w3-hover-opacity"></i>
      <i class="fa fa-snapchat w3-hover-opacity"></i>
      <i class="fa fa-pinterest-p w3-hover-opacity"></i>
      <i class="fa fa-twitter w3-hover-opacity"></i>
      <i class="fa fa-linkedin w3-hover-opacity"></i> -->
  </div>
  <p class="copyright permanentMarker">&copy; Mikko Holappa 2023</p>
  <p><a href="humans.txt"><img src="../assets/humanstxt-isolated-blank.gif" alt="humans.txt"/></a></p>
  </footer>
  </template>

<script>
export default {
  name: 'FooterSection'
}
</script>
