<template>
  <!-- Navbar -->
  <div class="w3-top">
    <div class="w3-bar w3-black w3-card w3-left-align w3-large">
      <a class="w3-bar-item w3-button w3-hide-medium w3-hide-large w3-right w3-padding-large w3-hover-white w3-large w3-black" href="javascript:void(0);" onclick="toggleMenu()" title="Toggle Navigation Menu"><i class="fa fa-bars"></i></a>
      <router-link to="/" class="w3-bar-item w3-button w3-padding-large" onclick="toggleMenu(true)">Coach Andy</router-link>
      <router-link to="/hieronta" :class="{ 'w3-white': $route.path === '/hieronta' }" class="w3-bar-item w3-button w3-hide-small w3-padding-large">Hieronta</router-link>
      <router-link to="/valmennus" :class="{ 'w3-white': $route.path === '/valmennus' }" class="w3-bar-item w3-button w3-hide-small w3-padding-large">Valmennus</router-link>
      <router-link to="/tietoaminusta" :class="{ 'w3-white': $route.path === '/tietoaminusta' }" class="w3-bar-item w3-button w3-hide-small w3-padding-large">Tietoa minusta</router-link>
      <router-link to="/yhteys" :class="{ 'w3-white': $route.path === '/yhteys' }" class="w3-bar-item w3-button w3-hide-small w3-padding-large">Ota yhteyttä</router-link>
    </div>

    <!-- Navbar on small screens -->
    <div id="navBurger" class="w3-bar-block w3-white w3-hide w3-hide-large w3-hide-medium w3-large">
      <router-link to="/hieronta" :class="{ 'w3-gray': $route.path === '/hieronta' }" class="w3-bar-item w3-button w3-padding-large" onclick="toggleMenu()">Hieronta</router-link>
      <router-link to="/valmennus" :class="{ 'w3-gray': $route.path === '/valmennus' }" class="w3-bar-item w3-button w3-padding-large" onclick="toggleMenu()">Valmennus</router-link>
      <router-link to="/tietoaminusta" :class="{ 'w3-gray': $route.path === '/tietoaminusta' }" class="w3-bar-item w3-button w3-padding-large" onclick="toggleMenu()">Tietoa minusta</router-link>
      <router-link to="/yhteys" :class="{ 'w3-gray': $route.path === '/yhteys' }" class="w3-bar-item w3-button w3-padding-large" onclick="toggleMenu()">Ota yhteyttä</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>
