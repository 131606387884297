<template>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Coach Andy",
          "item": "https://coachandy.fi"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Yhteystiedot"
        }]
      }
    </component>
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "ContactPage",
        "name": "Ota yhteyttä",
        "description": "Tilaa valmennuspalveluita tai kysy lisätietoja palveluistani.",
        "url": "https://coachandy.fi/yhteys",
        "image": "https://coachandy.fi/logo.png"
      }
    </component>
  </teleport>

  <!-- ContactPage -->
  <header class="bgimg w3-container w3-blue-gray w3-center">
    <p class="w3-margin pageTitle">Yhteys&shy;tiedot</p>
    <p>Ota yhteyttä</p>
  </header>

  <!-- Ota yhteyttä -->
  <div class="w3-row-padding w3-padding-64 w3-container">
    <div class="w3-content">

      <div>
        <h1 id="yhteys" class="sectionHead">Ota yhteyttä</h1>
        <h3 class="w3-padding-32">Tilaa valmennuspalveluita tai kysy lisätietoja.</h3>
        <p class="w3-text-grey">
          <b>Voit tilata valmennuspalveluita</b> ottamalla yhteyttä suoraan minuun puhelimitse tai sähköpostilla. Loydät yhteystietoni alta. Katso tarkemmat tiedot valmennuksista täältä:
          <br/>
          <router-link to="/valmennus" class="w3-button w3-round w3-black w3-large">Valmennus</router-link>
        </p>
        <p class="w3-text-grey">
          <b>Hieronnan ajanvaraus</b> tapahtuu Vello -ajanvarauspalvelun kautta täältä (avautuu uuteen välilehteen):
          <br/>
          <a class="w3-button w3-round w3-black w3-large" href="https://vello.fi/coachandy-hieronta/" target="_blank">Hieronnan ajanvaraus</a>
        </p>
        <p class="w3-text-grey">Voit tiedustella lisää palveluistani ottamalla yhteyttä puhelimitse tai sähköpostilla. Loydät yhteystietoni alta.</p>
        <p class="w3-text-grey"><b>Puhelin:</b> <a href="tel:+358503761776">050 376 1776</a></p>
        <p class="w3-text-grey"><b>Sähköposti:</b> <a href="mailto:antti@coachandy.fi">antti@coachandy.fi</a></p>
      </div>

    </div>
  </div>
  
</template>

<script>
import { useSeoMeta, useHead } from 'unhead';

export default {
    name: "ContactPage",
    setup() {
      useSeoMeta({
        title: 'Ota yhteyttä | Hieroja ja liikunnanohjaaja Antti Ovaska | Coach Andy',
        ogTitle: 'Ota yhteyttä | Hieroja ja liikunnanohjaaja Antti Ovaska | Coach Andy',
        description: 'Tilaa valmennuspalveluita tai kysy lisätietoja palveluistani. Tarjoan ammattimaisia hieronta- ja valmennuspalveluja Kajaanissa.',
        ogDescription: 'Tilaa valmennuspalveluita tai kysy lisätietoja palveluistani. Tarjoan ammattimaisia hieronta- ja valmennuspalveluja Kajaanissa.',
        ogUrl: 'https://coachandy.fi/yhteys'
      });
      useHead({
        link: [{
          rel: 'canonical', href: 'https://coachandy.fi/yhteys'
        }],
        meta: [
          {
            name: "keywords",
            content: "hieronta, hieroja, valmennus, valmentaja, urheiluvalmennus, training, personal trainer, koulutettu hieroja, hieroja kajaani, hieronta kajaani, valmennus kajaani, coach andy"
          }
        ]
      });
    },
    mounted() {
      document.dispatchEvent(new Event('render-complete')); 
    }
}
</script>
<style scoped>
  .bgimg {
    background-image: url("../assets/plants-1.jpg");
    background-size: cover;
    background-position: center;
  }
</style>
