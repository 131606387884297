import { createRouter, createWebHistory } from 'vue-router';
import FrontPage from '../views/FrontPage.vue';
import MassagePage from '../views/MassagePage.vue';
import CoachingPage from '../views/CoachingPage.vue';
import InfoPage from '../views/InfoPage.vue';
import ContactPage from '../views/ContactPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: FrontPage,
  },
  {
    path: '/hieronta',
    name: 'Massage',
    component: MassagePage,
  },
  {
    path: '/valmennus',
    name: 'Coaching',
    component: CoachingPage,
  },
  {
    path: '/tietoaminusta',
    name: 'Info',
    component: InfoPage,
  },
  {
    path: '/yhteys',
    name: 'Contact',
    component: ContactPage,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  }
  // Add more routes as needed
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // If the user is navigating back or forward, restore the saved position
      return savedPosition;
    } else {
      // Otherwise, scroll to the top of the page
      return { left: 0, top: 0 };
    }
  }
});

export default router;
