<template>
  <!-- BottomInfo -->
  <div class="w3-container w3-light-gray w3-center w3-padding-64">
    <div class="w3-third w3-center">
      <h2>Sijainti</h2>
      <p>Harjukatu 31</p>
      <p>87150 Kajaani</p>
    </div>
    <div class="w3-third w3-center">
      <h2>Hieronta-ajat</h2>
      <p>Ma-Pe 10-18</p>
      <p>(Pyynnöstä myös muina aikoina.)</p>
      <a class="w3-button w3-round w3-black w3-large" href="https://vello.fi/coachandy-hieronta/" target="_blank">
          Ajanvaraus
      </a>
    </div>
    <div class="w3-third w3-center">
      <h2>Yhteys&shy;tiedot</h2>
      <p><a href="mailto:antti@coachandy.fi">antti@coachandy.fi</a></p>
      <p><a href="tel:+358503761776">050 376 1776</a></p>
      <p>Y-Tunnus: 3223880-6</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomInfo'
}
</script>
<style scoped>

</style>